import React from 'react'
import ReactMarkdown from "react-markdown"
// import { MDXRenderer } from "gatsby-plugin-mdx"
import { graphql, Link } from 'gatsby'
// import BackgroundImage from "gatsby-background-image"
import TopMenu from '../components/topMenu'
import SEO from "../components/seo"
import Footer from "../components/footer"
import slugHandler from '../api/slugHandlerBrowser'
import Title from '../components/titles/title'
// import Rupor from '../components/view/rupor'

const NewsTemplate = ({ data, pageContext }) => {
    // console.log(data.settings);
  const { frontmatter: content } = data.content
  const slug = content.slug;
  const posts = data.posts.nodes;
  return (
    <section className={"page lang-" + pageContext.language}>
      <SEO
        title={content.title}
        description={content.description}
      />
      <div className="page__in container-1200">
      <TopMenu smallLogo={true} lang={content.language} languages={pageContext.languages} slug={slug} translation={pageContext.translation} settings={data.settings.childMdx.frontmatter}></TopMenu>
        <div className="mb4">

        <div className="columns columns--vertical-tablet-to aic mb3">
            <div className="column column-40 posr mr2">
              <Title label1={content.title}></Title>
            </div>
            <ReactMarkdown source={content.content} linkTarget="_blank" className="column column-60 text"/>
        </div>
        
        {/* <h1 className="color-gold">{content.title}</h1> */}

          <div className="news-list fdc">
            {
              posts
              // .filter(post => {
              //   const lang = post.slug.split('.')[1];
              //   return lang === content.language;
              // })
              .map((post, i)=>{
                const slug = post.slug.split('.')[0];
                const path = slugHandler(
                  content.language,
                  slug
                );
                const odd = i % 2;
                
                return (
                    <div className={"news-item news-page-item " + (odd ? "odd" : "even")} key={i}>
                        <div className="news-item__in news-page-item__in">
                            <img src={post.frontmatter.image} alt="new" className="news-item-img mb20" data-sal="slide-up" data-sal-duration="1000"/>
                            <div className="news-page-item-info news-item-info posr">
                                <div className="news-item-date mb1">{post.frontmatter.date}</div>
                                <h3 className="news-item-title mb1">{post.frontmatter.title}</h3>
                                {/* <div className="news-item-contain mb20">{post.frontmatter.description}</div> */}
                                <ReactMarkdown source={post.frontmatter.description} linkTarget="_blank" className="news-item-text mb1"/>
                                <Link to={path} className="link">{pageContext.translation.buttons.readMore}</Link>   
                            </div>
                        </div>
                    </div>
                    // <div key={album.id} className={"au-news-item mb100 " + (odd ? "odd" : "even")}>
                    //   <div className="columns">
                    //       <div className="column-40 column-1">
                    //         <img src={album.frontmatter.image} alt={album.frontmatter.title} loading="lazy" className="gallery-item__pic"/>
                          
                    //       </div>
                    //       <div className="column-60 column-2">
                    //         <div className="text-small color-gold fw300 mb1">{album.frontmatter.date}</div>
                    //         <h4 className="fwb">{album.frontmatter.title}</h4>
                    //         {/* <div className="mb1">{album.frontmatter.description}</div> */}
                    //         <ReactMarkdown source={album.frontmatter.description} linkTarget="_blank" className="text mb1"/>
                    //         {/* <Button type={2}>{pageContext.translation.buttons.readMore}</Button> */}
                    //         <Link to={path} className="link">{pageContext.translation.buttons.readMore}</Link>
                    //       </div>
                    //     </div>
                    // </div>
                )
              })
            }
          </div>
        </div>
      </div>
      <Footer lang={content.language} translation={pageContext.translation}></Footer>
    </section>
  )
}

export default NewsTemplate

export const indexQuery = graphql`
  query NewsById($id: String!, $language: String!, $langSlugRegEx: String!) {
    content: mdx(
      id: { eq: $id },
      frontmatter: {
        type: { eq: 0 }
        templateKey: { eq: "templates/news" }
        language: { eq: $language }
      }
    ) {
      frontmatter {
        slug
        language
        title
        description
        content
      }
    }
    posts: allMdx(filter: {frontmatter: {templateKey: {eq: "post"}, tags: {regex: "/news/"}}, slug: {regex: $langSlugRegEx }}, sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        slug
        id
        frontmatter {
          date(formatString: "DD MMM YYYY")
          description
          title
          image
        }
      }
    }
  
    settings: file(sourceInstanceName: {eq: "settings"}, name: {eq: "settings"}) {
      childMdx {
        frontmatter {
          detectLanguage
          address
          address2
          phone
          instagram
          facebook
          email
        }
      }
    }
    
  }
`